/**=====================
     Label scss
==========================**/
.label-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;

    .discount {
        background-color: var(--mainColor);
        color: $white;
        padding: 4px calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 4px;

        &.sm-discount {
            label {
                font-size: 12px;
            }
        }

        label {
            @include flex_common;
            margin: -2px 0;
        }
    }

    .wishlist {
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $content-color;
        margin-left: auto;

        [dir="rtl"] & {
            margin-left: unset;
            margin-right: auto;
        }
    }
}

.bonus-flex {
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;

    .bonus{
        background-color: var(--bonusColor);
        color: $white;
        padding: 4px calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 4px;
        position: relative;
        z-index: 1;

        svg{
            width: 9px;
            height: 18px;
        }
        
        span{
            font-size: 14px;
            margin-right: 5px;
            position: relative;
            top: -2px;
        }
    }
}